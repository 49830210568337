import React from 'react';
import style from './intro.mod.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../Layout/SharedStyles/Container';
import { Navigator } from '../LanguageHelpers/Navigator';
import Button from '../Button/Button';
import IconWavesSlider from '../Layout/Icons/IconWavesSlider';

const Intro = ({ title, text, cards }) => {
  return (
    <div className={style.intro}>
      <Container width="small" pt={'2'}>
        <div className={style.intro__content}>
          {title && <h2 className={style.intro__heading}>{title}</h2>}
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className={style.intro__text}
          />
        </div>
      </Container>
      <Container width="small" gutters={false} pb={'2'}>
        <div className={style.intro__products}>
          {cards.map(({ cardTitle, title, cardImage, cardCtaText, id }, i) => {
            return (
              <div
                className={[
                  i % 2 === 0 ? style.intro__card__up : style.intro__card__down,
                  style.intro__card,
                ].join(' ')}
                key={i}
              >
                <Navigator recordId={id}>
                  <div
                    className={
                      !cardImage
                        ? style.intro__allproducts
                        : style.intro__collection
                    }
                  >
                    {cardImage ? (
                      <GatsbyImage
                        className={style.intro__image}
                        image={cardImage.gatsbyImageData}
                      />
                    ) : (
                      <IconWavesSlider />
                    )}
                  </div>
                  <div className={style.intro__card__text}>
                    <h4 className={style.intro__card__name}>
                      {cardTitle ? cardTitle : title}
                    </h4>
                    {!cardImage && (
                      <Button
                        recordId={id}
                        className={style.intro__button}
                        light
                      >
                        {' '}
                        {cardCtaText}
                      </Button>
                    )}
                  </div>
                </Navigator>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Intro;
