import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import Hero from '../components/Hero/Hero';
import Intro from '../components/Intro/Intro';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ProductList from '../components/Product/ProductList';

const AllProduct = ({
  data: {
    datoCmsAllProduct: {
      title,
      collections,
      modularContent,
      products,
      metaTags,
      bannerText,
      bannerImage,
      introText,
      id,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];
  return (
    <PageWrapper home pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <Hero
        heading={bannerText}
        image={bannerImage}
        mobileImage={bannerImage}
      />
      <Intro text={introText} cards={collections} />
      <ProductList products={products} />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default AllProduct;

export const query = graphql`
  query AllProductsQuery($locale: String!) {
    datoCmsAllProduct(locale: { eq: $locale }) {
      locale
      title
      id: originalId
      metaTags {
        title
        description
        image {
          url
        }
      }
      bannerImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "600", fit: "crop", w: "1440", q: 60 }
        )
      }
      introText
      bannerText
      products {
        title
        id: originalId
        description
        tagLine
        productFeatureList {
          id
          value
          showOnProductCard
          specReference {
            title
            titleTranslation
          }
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "472"
              fit: "fill"
              fill: "solid"
              w: "778"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        hoverImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
      collections {
        cardImage {
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "800", fit: "crop", w: "1440", q: 60 }
          )
        }
        cardTitle
        title
        id: originalId
      }
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsFeatureBlock {
          ...Feature
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsPathwaysBlock {
          ...PathwaysBlock
        }
        ... on DatoCmsButtonListBlock {
          ...ButtonList
        }
        ... on DatoCmsTestimonialsBlock {
          ...Testimonials
        }
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
